import Vue from 'vue'
import VueGallery from 'vue-gallery'

export default {
  props: {
    item: {}
  },
  data() {
    return {
      activeIndex: 0,
      widthItemView: 0,
      heightItemView: 0,
      heightItemThumb: 0,
      disabler: true,
      open: false,
      index: null,
      showZm: false,
      zoomImg: null,
      left: 0,
      top: 0,
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    }
  },
  components: {
    'gallery': VueGallery,
  },
  mounted() {
    setTimeout(()=>{
      this.disabler = false
    }, 1000)
    Vue.nextTick(() => {
      this.widthItemView = this.$refs.viewContainer.offsetWidth;
      this.heightItemView = this.$refs.viewContainer.offsetHeight;
      this.heightItemThumb = this.$refs.itemThumb[0].clientHeight;
    })
  },
  name: "card-slider",
  computed: {
    imageLinks() {
      return this.item.map(e => e.link)
    }
  },
  methods: {
    showZoom(link, ev) {
      this.showZm = !this.showZm;
      this.zoomImg = link
      // var img = new Image()
      // img.src = link
      // this.width = img.width
      // this.height = img.height
      // this.x = -(this.width * (-1.6))
      // this.y = -(this.height * (-1.6))
      // console.log(this.x, this.y)
    },
    moveZoom(ev) {
      if (window.innerWidth > 1400) {
        this.top = -ev.offsetX / 1.5 // --- 1920
        this.left = -ev.offsetY / 0.8
      } else {
        this.top = -ev.offsetX / 1.6 // --- 1920
        this.left = -ev.offsetY / 4.36
      }
    },
    closeGallery() {
      // this.activeIndex = null
      this.open = false
      // this.fixBody(0)
    },
    openGallery() {
      this.open = true
      // this.fixBody(1)
    },
    changeActiveSlideIndex(index) {
      this.activeIndex = index
    },
    changeIndex(direction) {
      if (direction === 'prev') {
        if (this.activeIndex > 0) {
          this.activeIndex--;
        }
      } else if (direction === 'next') {
        if (this.activeIndex < this.item.length - 1) {
          this.activeIndex++;
        }
      }
    },
  }
}