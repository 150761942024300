import {mapActions, mapGetters, mapMutations} from "vuex";
import cardSlider from "./components/slider/index.vue"
import product from '@/components/product/index.vue'
import VueSocialSharing from 'vue-social-sharing'
import VueHead from 'vue-head'
import VueRouter from 'vue-router'
import customBreadcrumbs from '@/components/custom-breadcrumbs/index.vue'

Vue.use(VueRouter)
import Vue from 'vue'

Vue.use(VueHead)
Vue.use(VueSocialSharing);

export default {
  props: ['item'],
  name: "card",
  data() {
    return {
      selectCount: 1,
      changeTab: false,
      location: '',
      like:false,
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
        micro: {
          product: '',
          comments: ''
        }
      },
      category: null,

    }
  },
  head: {
    title: function () {
      return {
        inner: this.params.title !== '' ? this.params.title : '',
        separator: ' ',
      }
    },
    meta: function () {
      return [
        // {name: 'description', content: this.params.description},
        {n: 'keywords', content: this.params.keywords, id: 'keywords'},
        {p: 'og:title', content: this.params.title, id: 'og:title'},
        {p: 'og:description', content: this.params.description, id: 'og:description'},
        {n: 'title', content: this.params.title , id: 'title'},
        {n: 'description', content: this.params.description , id: 'description'},
        {p: 'og:image', content: this.params.image, id: 'og:image'},
        // {name: 'image', content: this.params.image},
      ]
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ru/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' },
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + '/ru' + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    },
    script: function() {
      return [
        {
          t: 'application/ld+json',
          i: this.product.micro.product
        },
      ]
    }
  },
  components: {
    cardSlider,
    product,
    customBreadcrumbs
  },
  mounted() {
    this.getProducts()
    this.location = window.location.href
    this.fetchSameProducts(this.$route.params.item)
    this.fetchOftenProducts(this.$route.params.item)

  },
  watch: {
    '$route'() {
      this.fetchSameProducts(this.$route.params.item)
      // this.fetchProduct(this.$route.params.item)
      this.getProducts()
      this.fetchOftenProducts(this.$route.params.item)
    },
    product: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.setMicro()
          this.setMeta()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      addedToBasket: `basket/addedToBasket`,
      isAuthenticated: `auth/isAuthenticated`,
      dataProducts: `card/product`,
      sameProducts: `card/sameProducts`,
      oftenProducts: `card/oftenProducts`,
      isAuth: `auth/isAuthenticated`,
      currentCurrency: `setting/currentCurrency`,
      productLoading: `card/productLoading`,
      currentCategory: `category/subCategories`,
      cardBreadcrumbs: `card/cardBreadcrumbs`,
      favourites: 'favorites/whichList',
    }),
    product() {
      if (this.isAuthenticated) {
        if (!this.dataProducts) return {}
        if (!this.favourites) return []
        const item = this.favoriteProducts.find(t => t.id === this.dataProducts.id)
        this.dataProducts.selected = !!item
        return this.dataProducts
      } else {
        return this.dataProducts
      }
    },
    favoriteProducts(){
      let arr = []
      if(this.isAuthenticated){
        for(let j in this.favourites){
          for(let i in this.favourites[j].products){
            arr.push(this.favourites[j].products[i])
          }
        }
        return arr
      }
    }
  },
  methods: {
    ...mapActions({
      fetchProduct: `card/GET_PRODUCT`,
      addFavourite: `favorites/ADD_PRODUCT_TO_FAVORITE`,
      fetchSameProducts: `card/GET_SAME_PRODUCT`,
      fetchOftenProducts: `card/GET_OFTEN_PRODUCT`,
      fetchCategory: `category/GET_SUB_CATEGORIES`,
    }),
    ...mapMutations({
      setProduct: `card/SET_PRODUCT`,
      changePopupAddComment: `card/CHANGE_POPUP_ADD_COMMENT`,
      addBasket: `basket/ADD_ITEM_TO_BASKET`,
      changePopupLogin: `auth/CHANGE_POPUP_LOGIN`,
    }),
    getProducts() {
      let obj = {}
      obj.slug = this.$route.params.item
      if (this.$route.params.secondSlug) {
        this.fetchCategory(this.$route.params.secondSlug).then(() => {
          obj.category_id = this.currentCategory.id
          this.fetchProduct(obj)
        })
      } else {
        this.fetchProduct(this.$route.params.item)
      }
    },
    setMeta() {
      this.params.title = this.product.meta.meta_title !== '' ? this.product.meta.meta_title : 'ICHIBAN STORE';
      this.params.description = this.product.meta.meta_description !== '' ? this.product.meta.meta_description : 'ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE';
      this.params.keywords = this.product.meta.meta_keywords;
      this.params.image = this.product.image !== '' ? this.product.image : 'https://ichibanstore.jp/logo.png';
      this.$emit('updateHead');
    },
    setMicro() {
      this.params.micro.product = this.product.micro.product;
      this.params.micro.comments = this.product.micro.comments
    },
    addComment() {
      if (this.isAuth) {
        this.changePopupAddComment(true)
      } else {
        this.changePopupLogin(true)
      }
    },
    addFavorite(item) {
      let obj = {}
      obj.product_id = item
      if (this.isAuthenticated) {
        this.addFavourite(obj).then(() => {
          this.like = true
          this.$toasted.success(this.$t('successAddFavourites'));
        }).catch(error => {
          console.log(error);
          if (error.response.status === 422) {
            console.log(this.validationErrors);
          }
        });
      } else {
        this.changePopupLogin(true);
      }
    },
    changeCount(type, count = 1) {
      if (type === 'increment') {
        if(this.product.count_is_limited === 0) {
          this.selectCount++
        } else {
          if (this.selectCount >= this.product.count) {
            this.$toasted.error(this.$t('limitProduct'));
          }  else {
            this.selectCount++
          }
        }
      } else if (type === 'decrement') {
        this.selectCount--
      } else {
        if (count) {
          this.selectCount = count
        }
      }
    },
    addInCard(product) {
      this.addBasket({
        select_count: this.selectCount,
        ...product
      })
      if(this.addedToBasket) {
        this.$toasted.success(this.$t('successAddBasket'));
      } else {
        this.$toasted.error(this.$t('limitProduct'));
      }
    },
    navigationTo() {
      this.$router.push({name: 'profile-favorite'}).catch(() => {
        console.log('')
      })
    },
  }
}
